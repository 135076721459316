<template>
  <el-container class="home-container">
    <el-header>
      <div>
        <span class="title">虾谷快运管理后台</span>
      </div>
      <div class="right">
        <p style="font-weight: 600">{{ userInfo.fullName }}</p>
        <div>
          <img src="~assets/xiamiImg/tx.png" alt=""/>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <img src="~assets/xiamiImg/next.png" alt="" class="nextImg"/>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="quits" @click.native="signOut">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </el-header>

    <el-container>
      <el-main>
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <template v-for='(menu,index) in menus' )>
              <div class="swiper-slide" @click="gateway(menu.pathUrl)"
                   :style="'background-image:url('+menu.bg+') !important'">
                <img :src="menu.pic"/>
                <div>
                  <p>{{ menu.title }}</p>
                </div>
              </div>
            </template>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </el-main>
    </el-container>
  </el-container>

</template>

<script>
import Swiper from 'swiper'
import 'swiper/swiper-bundle.min.css'
import {bindWechatByCode} from '@/api/home';
import {mapState} from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      index: 9,
      menuList: {
        'subCompanyAdmin': {
          pic: require("../assets/xiamiImg/htgl.png"),
          pathUrl: "sub_web_admin/",
          title: "后台管理",
          bg: require("../assets/xiamiImg/5.png")
        },
        'dispatcherAdmin': {
          pic: require("../assets/xiamiImg/ddcx.png"),
          pathUrl: "dispatch/",
          title: "调度查询",
          bg: require("../assets/xiamiImg/2.png")
        },
        'subCompanyCensus': {
          pic: require("../assets/xiamiImg/fztj.png"),
          pathUrl: "tongji/",
          title: "分站统计",
          bg: require("../assets/xiamiImg/3.png")
        },
        'pcRoute': {
          pic: require("../assets/xiamiImg/htgl.png"),
          pathUrl: "peizai/",
          title: "智能配载",
          bg: require("../assets/xiamiImg/1.png")
        },
        'invoice': {
          pic: require("../assets/xiamiImg/kp.png"),
          pathUrl: "kaipiao/",
          title: "开票管理",
          bg: require("../assets/xiamiImg/7.png")
        },
        'insuranceTicket': {
          pic: require("../assets/xiamiImg/kp.png"),
          pathUrl: "insuranceTicket/",
          title: "保险出票",
          bg: require("../assets/xiamiImg/7.png")
        },
        'route': {
          pic: require("../assets/xiamiImg/kp.png"),
          pathUrl: "peizai_print/",
          title: "线路打印",
          bg: require("../assets/xiamiImg/7.png")
        },
        // 'largeScreenFabricate': {
        //   pic: require("../assets/xiamiImg/kp.png"),
        //   // pathUrl: "largeScreenFabricate/",
        //   pathUrl: "https://ai.goviewlink.com/chart/preview/clzi2x8q20001g3hnibz0r62y/",
        //   title: "可视化大屏",
        //   bg: require("../assets/xiamiImg/7.png")
        // },
        'largeScreen': {
          pic: require("../assets/xiamiImg/kp.png"),
          // pathUrl: "largeScreen/",
          pathUrl: "https://ai.goviewlink.com/chart/preview/clzi2x8q20001g3hnibz0r62y/",
          title: "可视化大屏",
          bg: require("../assets/xiamiImg/7.png")
        },
        'picker': {
          pic: require("../assets/xiamiImg/kp.png"),
          pathUrl: "picker/",
          title: "揽件配载",
          bg: require("../assets/xiamiImg/7.png")
        },
      },
      menus: [],
    }
  },
  mounted() {
    let slidesPerView;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // 在移动设备上执行的代码
      slidesPerView = 1
    } else {
      slidesPerView = 4
      // 在桌面设备上执行的代码
    }
    new Swiper('.swiper-container', {
      slidesPerView,
      spaceBetween: 10,
      freeMode: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })
  },
  computed: {
    ...mapState(['userInfo', 'hasLogin']),
  },
  async created() {
    if (!this.$store.state.userInfo) {
      this.signOut();
      return
    }
    if (this.$store.state.expires_in) {
      if (this.$store.state.expires_in < new Date().getTime()) {
        this.signOut();
        return
      }
    }
    await this.bindWechatByCode();
    const roleNames = this.$store.state.userInfo.roleNames;
    for (let i = 0; i < roleNames.length; i++) {
      const menuListElement = this.menuList[roleNames[i]];
      if (menuListElement) {
        this.menus.push(menuListElement);
      }
    }
    const authorities = this.$store.state.userInfo.authorities;
    if (roleNames.indexOf("subCompanyAdmin") === -1 && authorities != null && authorities.length > 0) {
      this.menus.push(this.menuList["subCompanyAdmin"]);
    }
  },
  methods: {
    async bindWechatByCode() {
      const queryParams = this.$route.query
      if (queryParams.bind && queryParams.code) {
        await bindWechatByCode({
          code: queryParams.code,
          providerName: "wechatOpen"
        })
      }
    },
    gateway(pathUrl) {
      if (pathUrl === "https://ai.goviewlink.com/chart/preview/clzi2x8q20001g3hnibz0r62y/") {
        window.open(pathUrl);
      }
      let host = window.location.host;
      if (host.indexOf("localhost") !== -1 && host.indexOf("127.0.0.1") !== -1 && host.indexOf("192") !== -1) {
        host = "https://www.xiagukuaiyun.com/";
      } else {
        host = "http://" + host + "/";
      }
      window.open(host + pathUrl);
    },
    signOut() {
      this.$router.push('/login');
      this.$store.commit('logout');
    }
  }
}
</script>

<style lang="less">

@media (max-width: 768px) {
  /* 在屏幕宽度小于等于 768 像素时应用的样式 */
  .title {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
  }
}

@media (min-width: 769px) {
  /* 在屏幕宽度大于 768 像素时应用的样式 */
  .title {
    font-size: 29px;
    font-weight: 700;
    letter-spacing: 1px;
  }
}

.el-header {
  background-color: #099cf9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 80px !important;


  .right {
    display: flex;

    p {
      margin-right: 32px;
    }

    div {
      display: flex;
      align-items: center;

      img {
        margin-right: 18px;
      }

      img:nth-child(1) {
        width: 50px;
      }

      .nextImg {
        width: 18px !important;
        height: 10px !important;
      }
    }
  }
}

.el-main {
  background-color: #082259;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: calc(100vh - 80px);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  position: relative;
}

.swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 200px;
}

.swiper-slide {
  width: 352px;
  font-size: 18px;
  //background: rgb(54, 158, 115);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8%;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.swiper-slide img {
  width: 160px;
  height: 160px;
  margin-top: 80px;
}

.swiper-slide p {
  color: #fff;
  font-size: 22px;
  letter-spacing: 6px;
  margin-top: 40px;
}

.swiper-pagination-bullet {
  width: 28px;
  border-radius: 4px;
  background: rgb(255, 255, 255);
}
</style>
